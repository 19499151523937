export const userList = [
  'edu_test1@hse.ru',
  'ev.bun@hse.ru',
  'aminakova@hse.ru',
  'mariya.grigoreva@hse.ru',
  'gseregin@hse.ru',
  'ag.smirnov@hse.ru',
  'k.kasyanova@hse.ru',
  'opeskova@hse.ru',
  'rnesterenko@hse.ru',
  'gasoyan@hse.ru',
  'evkornilov@edu.hse.ru',
  'kaksenofontov@edu.hse.ru',
  'ivpidluzhnaya@edu.hse.ru',
  'lagerget@edu.hse.ru',
  'almaltseva@edu.hse.ru',
  'ayudenisova@edu.hse.ru',
  'aaabyazova@edu.hse.ru',
  'asandriianova@edu.hse.ru',
  'gbasanova@edu.hse.ru',
  'nibaklanov@edu.hse.ru',
  'dgbezrukov@edu.hse.ru',
  'nabelousov@edu.hse.ru',
  'dsvereikina@edu.hse.ru',
  'vavizgalova@edu.hse.ru',
  'analvlasov@edu.hse.ru',
  'gmvorobev@edu.hse.ru',
  'lrgasanova@edu.hse.ru',
  'msgornostaev@edu.hse.ru',
  'mvgromchenko@edu.hse.ru',
  'avdemochka@edu.hse.ru',
  'dadzyuba@edu.hse.ru',
  'nvdmitriev@edu.hse.ru',
  'apevgrafova@edu.hse.ru',
  'iazavialova@edu.hse.ru',
  'vvzastupnevich@edu.hse.ru',
  'igzakharova@edu.hse.ru',
  'rvivannikov@edu.hse.ru',
  'tvimkin@edu.hse.ru',
  'aniukazakov@edu.hse.ru',
  'vkkalinichenko@edu.hse.ru',
  'dmklenin@edu.hse.ru',
  'makolodezneva@edu.hse.ru',
  'dakostyshin@edu.hse.ru',
  'mmlaptev@edu.hse.ru',
  'mvlepsaia@edu.hse.ru',
  'aglisavina@edu.hse.ru',
  'epmakashina@edu.hse.ru',
  'tsmaleev@edu.hse.ru',
  'tnmamedov@edu.hse.ru',
  'mvmatskepladze@edu.hse.ru',
  'aomilykh@edu.hse.ru',
  'epnemchinova@edu.hse.ru',
  'nvnikulina@edu.hse.ru',
  'dsondar@edu.hse.ru',
  'nvpampukha@edu.hse.ru',
  'evevpetrov@edu.hse.ru',
  'rvponomar@edu.hse.ru',
  'eiredko@edu.hse.ru',
  'mamiromanova@edu.hse.ru',
  'masaprykin@edu.hse.ru',
  'irsaribekova@edu.hse.ru',
  'rasafonov@edu.hse.ru',
  'mdsolovev@edu.hse.ru',
  'irtalachev@edu.hse.ru',
  'avtarakanov@edu.hse.ru',
  'ivtoporkova@edu.hse.ru',
  'datrifonov@edu.hse.ru',
  'motrykin@edu.hse.ru',
  'vivichernikova@edu.hse.ru',
  'mmshagrov@edu.hse.ru',

  'vsabdurakhmanov@edu.hse.ru',
  'anruazizov@edu.hse.ru',
  'analandreeva@edu.hse.ru',
  'rraiupov@edu.hse.ru',
  'esbalashova@edu.hse.ru',
  'ambasak@edu.hse.ru',
  'srbatyrshina@edu.hse.ru',
  'snbelova@edu.hse.ru',
  'vabokov@edu.hse.ru',
  'aebykov@edu.hse.ru',
  'imgrinina@edu.hse.ru',
  'ivmidavydov@edu.hse.ru',
  'kedzhamgaev@edu.hse.ru',
  'midiumin@edu.hse.ru',
  'gazamakhshariev@edu.hse.ru',
  'mszelenova@edu.hse.ru',
  'dkzinchin@edu.hse.ru',
  'rikuzmin@edu.hse.ru',
  'aokurbatova@edu.hse.ru',
  'dglev@edu.hse.ru',
  'ammamedova@edu.hse.ru',
  'esnavetkina@edu.hse.ru',
  'nanaletova@edu.hse.ru',
  'lanogaeva@edu.hse.ru',
  'andmorlova@edu.hse.ru',
  'avpatrikeev@edu.hse.ru',
  'vesepetrova@edu.hse.ru',
  'mipirogova@edu.hse.ru',
  'raprianikov@edu.hse.ru',
  'adpcheliakov@edu.hse.ru',
  'nnrazumovskaia@edu.hse.ru',
  'pasobakinskaya@edu.hse.ru',
  'avsuftina@edu.hse.ru',
  'aataliarenko@edu.hse.ru',
  'amfitisov@edu.hse.ru',
  'ivshimanskii@edu.hse.ru',
  'lkustovinova@hse.ru',
  'pkorchagin@hse.ru',
  'smirnova.a.a@hse.ru'
];
